export const environment = {
  isAdminUI: false,
  debug: false,
  production: true,
  homeRoute: "dashboard",
  apiUrl: "",
  impersonateAuthToken: "",
  impersonateLoginName: "",
  impersonateLoginUserID: "",
  impersonateIsOnline: false,
  impersonateUserTypeId: "",
  appUrl: "",
  meetingUrl: "",
  signalrUrl: "",
  faceBookUrl: "https://www.facebook.com/v6.0/dialog/oauth?client_id=599639803996693&redirect_uri=https://webdev.mylifead.com/auth/facebooklogin&response_type=code&scope=email,public_profile&",
  googleUrl: "https://accounts.google.com/o/oauth2/auth?client_id=708417138228-d2q73sd3jjor5qjra2c65bekarsvfhfg.apps.googleusercontent.com&scope=email profile&include_granted_scopes=true&immediate=false&&response_type=code&redirect_uri=https://webdev.mylifead.com/auth/gmaillogin",
  googleContactUrl: "https://accounts.google.com/o/oauth2/auth?client_id=708417138228-d2q73sd3jjor5qjra2c65bekarsvfhfg.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/contacts.readonly&include_granted_scopes=true&immediate=false&&response_type=code&redirect_uri=http://localhost:4200/auth/googlecontactsync",
  appleUrl: "",
  authorizepaymentform: "",
  contentUrl: "",
  env: "https://api-settings.mylifead.com/web_prd.json",
  appVersion: "3",
  S3Buclket: "https://prod-mylifeadstaticcontent-terrence.s3.amazonaws.com",
  RocketSignBaseUrl : "https://document-manager.rocketlawyer.com/",
  enableOnlyForDevelopment: false
};
