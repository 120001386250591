<app-masterview [navItems]="getNavItems()" (onNavSelected)="onNavSelected($event)">


    <div position="content">
        <!-- <div ngDraggable (positionChange)="dragged($event)" class="tele-div" [ngClass]="getClass()" [ngStyle]="{'width.px': minimize ? 370 : undefined, 'left.px': left, 'top.px': top}">
            <video controls playsinline [hidden]="!isvideostartrecord" id="video" autoplay></video>
            <video controls playsinline [hidden]="!isvideostartrecord" id="videopreview" autoplay></video> -->
            <!-- <div [hidden]="!isvideostartrecord" style="position: absolute;top: 80%;right: 39%;">
                <a style="margin: 0px 21px 0px 0px;" (click)="onPlay()">
                    <i style="font-size: 3rem; color: red;" class="fas fa-play-circle"></i>
                </a>
                <a href="#">
                    <i style="font-size: 3rem; color: #de5246;" class="fad fa-pause-circle"></i>
                </a>
            </div> -->
        <!-- </div> -->
        <!-- <div ngDraggable [handle]="DemoHandle">
         
        </div> -->
        <router-outlet></router-outlet>
    </div>
</app-masterview>