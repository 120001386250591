import { Injectable } from '@angular/core';
import { HttpService, ApiResponse, ApiActionSettings } from 'src/app/core/services/http.service';
import { PageService } from 'src/app/core/services/page.service';
import {
  ManageEntityRequest,
  EntityListRequest,
  EntityScheduleDetailsRequest,
  EntityDeliveryRequest,
  TwoFactorAuth
} from '../models/manageentityrequest';
import { DeleteEntityRequest, DeleteContactRequest } from '../models/entityrequestresponse';
import { RocketLawyerDocument } from '../models/documentlibrary';


@Injectable()
export class EntityManageService {
  constructor(private pageservice: PageService, private http: HttpService) {
  }

  GenerateFormPdf(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/generateformpdf", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  GetEntityList(request: EntityListRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/listing", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  GetCompanyUsers(page, perpage): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Get("/entity/get_company_users?page=" + page + "&perpage=" + perpage).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  Manage(request: ManageEntityRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/manage", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  SendAdminEmail(request: ManageEntityRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/send-admin-email", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  CreateContact(request: ManageEntityRequest.Contact): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/contact/create", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  ChargeDocument(req: RocketLawyerDocument.ChargeRequest): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/charge_document_library", req, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  GetContactDetails(): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/contact/listing", null, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  DeleteEntity(request: DeleteEntityRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/delete", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  DeleteContact(request: DeleteContactRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/contact/delete", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  EntityScheduleDetails(request: EntityScheduleDetailsRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/delivery", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  ResendEntityDelivery(request: EntityDeliveryRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/resend", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  VerifyTwoFactorAuth(request: TwoFactorAuth.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/verify-two-fa", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  ExportToPdf(request: any, isHTML: boolean = null): Promise<Blob> {
    let url = "/entity/exportpdf?uuid=" + request;
    if (isHTML) {
      url += "&ishtml=true"
    }
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = true;
    return new Promise<Blob>((successresolve, failureresolve) => {
      this.http.GetPdfFile(url, undefined, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  GetRocketLawyerDocument(tobj: any = {}): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/get_document", tobj, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  SignRequestRocketLawyerDocument(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/get_binderid", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  DocumentStatus(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/get_binder_status", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  GetFormFields(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/getformfields", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  GetFolderCount(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/getfoldercounts", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  CanAdd(request: any) {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity/canadd", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  ShowPaidAccountAlert(): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Get("/entity/showpaidaccountalert", undefined, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }
}
