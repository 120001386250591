import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { PageService } from 'src/app/core/services/page.service';
import { Router, NavigationEnd, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationError } from '@angular/router';
import { MyLifeADBaseView } from '../provider/models/MYLifeADBaseView';
import { ApiResponse, HttpService } from 'src/app/core/services/http.service';
import { ConfigService } from '../shared/services/config.service';
import { EventService } from 'src/app/core/services/event.service';
import { Subscription } from 'rxjs';
import { DialogComponent } from 'src/app/core/components/dialog/dialog.component';
import { MessagesService } from '../shared/services/messages.service';
import { timer } from 'rxjs';
import { EntityManageService } from '../shared/services/entitymanage.service';
import { DeleteContactRequest } from '../shared/models/entityrequestresponse';
import { CacheService } from "../../core/services/cache.service";

import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [MessagesService, EntityManageService]
})
export class MainComponent extends MyLifeADBaseView implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dialogue', { static: false }) dialogue: DialogComponent;
  size: number = 2;

  minimize: boolean = false;
  isvideostartrecord: boolean = false
  deletecontactReq: DeleteContactRequest.Request = {} as DeleteContactRequest.Request
  private routeURL: string;
  routerEventSubscription: Subscription;
  eventServiceSubscription: Subscription;
  tSubscription: Subscription;
  RefreshTokenSubscription: Subscription;
  urlpipline: string[] = ['adminaccess'];
  data: ApiResponse;

  titleMap: string[] = [];
  constructor(pageservice: PageService, httpservice: HttpService,
    private eventService: EventService, private cacheServ: CacheService,
    private router: Router, private msgServ: MessagesService, private manageSer: EntityManageService,
    private title: Title) {
    super(pageservice);

    this.titleMap['/auth'] = 'myLife Login'
    this.titleMap['/auth/signup'] = 'myLife Sign Up'

    this.routerEventSubscription = this.router.events.subscribe(data => {
      if (data instanceof NavigationError) {
        let url = data.url.substring(1)
        let isurlExist = this.urlpipline.forEach(x => {
          if (url.includes(x)) {
            Swal.fire({
              title: "Development",
              text: "This module is still in the development",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "Close",
              showConfirmButton: false
            })
          }
        })
      } else if (data instanceof NavigationEnd) {
        let tElement = this.titleMap[data.url];
        if (tElement) {
          this.title.setTitle(tElement);
        }
        else {
          this.title.setTitle('myLife');
        }
        setTimeout(() => {
          this.getPageService().checkAccountProcess();
        }, 5000);

        let tempURL = data.url.split('/');
        if (tempURL.length > 1) {
          this.routeURL = tempURL[1];
        }
        this.getPageService().showResourceUsageBar = false;
        this.setRouteModule();

        // setTimeout(() => {
        //   if (this.getPageService().isRestrictedModule && !this.getPageService().IsAdminUser())
        //     this.getPageService().route("/restricted-module")
        // }, 1000);
      } else if (data instanceof RouteConfigLoadStart) {
        this.setPageBusy();
      } else if (data instanceof RouteConfigLoadEnd) {
        this.setPageNotBusy();
      }
    })
    this.eventServiceSubscription = this.eventService.observable().subscribe(data => {
      console.log('Event Type XXXXXX ', data.type);
      if (data.type == "SIGNING_IN") {
        this.setRouteModule();
        // const refreshTokenTime = timer(20 * 60 * 1000, 25 * 60 * 1000);
        // this.RefreshTokenSubscription = refreshTokenTime.subscribe(data => {
        //   console.log("i am called")
        // })
        const time = timer(1000, 30000);
        this.tSubscription = time.subscribe(data => {
          this.eventService.publish3("Inbox_Count_Refresh");
        })
      }
      // else if (data.type == "Listing Loaded") {
      //   setTimeout(() => {
      //     if (this.getPageService().isRestrictedModule && !this.getPageService().IsAdminUser())
      //       this.getPageService().route("/restricted-module")
      //   }, 1000);
      // }
      else if (data.type == "MENU_UPDATE" && this.getPageService().GetUserPurchases() && !this.getPageService().IsAdminUser()) {
        console.log('MENU_UPDATE 1');
        this.loginNavItems = [];
        let menuList = this.getPageService().modulesList;

        /*this.loginNavItems.push({
          title: menuList[0].name,
          value: menuList[0].id,
          icon: menuList[0].icon,
          route: menuList[0].route,
          twofa: menuList[0].twofa,
          dashboardstyle: menuList[0].dashboardstyle,
          isvisible: true,
        });*/
        // this.loginNavItems.push({
        //   title: "My Profile",
        //   value: 0,
        //   icon: "fal fa-user-circle",
        //   // icon: 'assets/images/navicon2.svg',
        //   route: 'profile',
        //   isvisible: true
        // });

        // this.loginNavItems.push({
        //   title: "Payments",
        //   value: 0,
        //   icon: 'assets/images/navicon13.svg',
        //   route: 'payment'
        // });

        for (let i = 0; i < menuList.length; i++) {
          if (menuList[i].route == "trusteeaccess") {
            this.loginNavItems.push({
              title: menuList[i].name,
              value: menuList[i].id,
              icon: menuList[i].icon,
              route: menuList[i].route + "/list",
              twofa: menuList[i].twofa,
              dashboardstyle: menuList[i].dashboardstyle,
              isvisible: true,
              walkthrough: menuList[i].walkthrough
            });

          } else {
            this.loginNavItems.push({
              title: menuList[i].name,
              value: menuList[i].id,
              icon: menuList[i].icon,
              route: menuList[i].route,
              twofa: menuList[i].twofa,
              dashboardstyle: menuList[i].dashboardstyle,
              isvisible: true,
              walkthrough: menuList[i].walkthrough
            });
          }
        }
        console.log(this.loginNavItems);
        this.getPageService().setLoginNavItems(this.loginNavItems);
        this.eventService.publish3("Inbox_Count_Refresh");

        // this.loginNavItems.push({
        //   title: "Logout",
        //   value: 0,
        //   icon: 'assets/images/navicon12.svg',
        //   route: 'signout'
        // });

        if (this.loginNavItems && this.loginNavItems.length > 0) {
          this.showLeftNav();
        }
        // this.setRouteModule();
      } else if (data.type == "MENU_UPDATE" && this.getPageService().IsAdminUser()) {
        console.log('MENU_UPDATE 2');
        this.loginNavItems = [];

        let menuList = this.getPageService().modulesList;
        /*this.loginNavItems.push({
          title: menuList[0].name,
          value: menuList[0].id,
          icon: menuList[0].icon,
          route: menuList[0].route,
          twofa: menuList[0].twofa,
          dashboardstyle: menuList[0].dashboardstyle,
          isvisible: true,
        });*/


        for (let i = 0; i < menuList.length; i++) {

          if (menuList[i].route == "trusteeaccess") {
            this.loginNavItems.push({
              title: menuList[i].name,
              value: menuList[i].id,
              icon: menuList[i].icon,
              route: menuList[i].route + "/list",
              twofa: menuList[i].twofa,
              dashboardstyle: menuList[i].dashboardstyle,
              isvisible: true,
              walkthrough: menuList[i].walkthrough
            });

          } else {
            this.loginNavItems.push({
              title: menuList[i].name,
              value: menuList[i].id,
              icon: menuList[i].icon,
              route: menuList[i].route,
              twofa: menuList[i].twofa,
              dashboardstyle: menuList[i].dashboardstyle,
              isvisible: true,
              walkthrough: menuList[i].walkthrough
            });
          }
        }
        console.log(this.loginNavItems);
        this.getPageService().setLoginNavItems(this.loginNavItems);
        this.eventService.publish3("Inbox_Count_Refresh");
        if (this.loginNavItems && this.loginNavItems.length > 0) {
          this.showLeftNav();
        }
        // this.setRouteModule();
      } else if (data.type == "SIGNED_OUT") {
        this.tSubscription.unsubscribe();
      } else if (data.type == "HIDE_ALL_MENU") {
        this.loginNavItems.forEach(x => {
          x.isvisible = false
        })
      } else if (data.type == "SHOW_ALL_MENU") {
        if (this.getPageService().GetUserPurchases())
          this.loginNavItems.forEach(x => {
            x.isvisible = true
          })
      } else if (data.type == "SHOW_MENU") {
        if (this.getPageService().IsAdminUser() && this.getPageService().getAdminStatus() == "deceased") {
          this.loginNavItems.forEach(x => {
            if (x.value == data.data)
              x.isvisible = true
          })
        }
      } else if (data.type == "Inbox_Count_Refresh" && this.getPageService().GetUserPurchases()) {
        if (this.getPageService().isLogin() && !this.getPageService().getNetworkCallByPass()) {
          this.msgServ.GetInboxCount().then(res => {
            if (this.loginNavItems && this.loginNavItems.length > 0) {
              let tMenu = this.loginNavItems.find(x => {
                return x.title == "Inbox"
              });

              if (tMenu) {
                tMenu.count = res.Data;
              }
            }

          })
        }
      } else if (data.type == "Contact_Is_Being_Used") {
        Swal.fire({
          title: data.data.errorMessage,
          text: `Are you sure you want to delete this contact this action cannot be undone?`,
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Close",
          confirmButtonText: "Delete Contact"
        }).then((willDelete) => {
          if (willDelete.isConfirmed) {
            this.setPageBusy();
            this.deletecontactReq.contactId = data.data.Data.contactId
            this.deletecontactReq.isForce = true;
            this.manageSer.DeleteContact(this.deletecontactReq).then(res => {
              this.eventService.publish3("Contact_Delete_Success");
              this.showSuccessMsg(res.Message);
            }).catch(err => {
              this.showErrorMsg(err.Message);
              this.setPageNotBusy();
            }).finally(() => {
              this.setPageNotBusy();

            })
          }
        });


      } else if (data.type == "Video_Start_Record") {
        this.isvideostartrecord = true
      } else if (data.type == "Buy_More_Space") {
        console.log("Redirecting to buy more space page...");
      }
    })
    // httpservice.SetBaseURL(environment.apiUrl);

  }

  onPlay() {

    alert("ok");
  }

  isDgLoading() {
    return this.data == undefined;
  }

  ngAfterViewInit() {
    // console.log(this.isLogin());
    // setTimeout(() => {
    //     this.getPageService().openMainNav();
    // }, 10);
    this.eventService.publish3("MENU_UPDATE");
  }

  ngOnDestroy() {
    // this.routerEventSubscription.unsubscribe();
    // this.eventServiceSubscription.unsubscribe();
    // this.tSubscription.unsubscribe();
    // this.RefreshTokenSubscription.unsubscribe();
  }

  setRouteModule() {

    let navItem = this.loginNavItems.find(data => {
      return data.route == this.routeURL || data.route == this.routeURL + "/list";
    })
    if (navItem) {
      if (navItem.value != 7) {
        const groups = ConfigService.AppConfig.Settings.VirtualVaultGroups
        const folders = ConfigService.AppConfig.Settings.VirtualVaultFolders;
        this.cacheServ.setCacheData("Virtual_Valut_Selected_Group", groups[0]);
        this.cacheServ.setCacheData("Virtual_Valut_Selected_Folder", folders[0]);
      }
      this.eventService.publish2("MODULE_ID", navItem.value);
      this.setModuleId(navItem.value);
      this.setModuleName(navItem.title);
      this.setTwoFARequired(navItem.twofa);
      this.getPageService().setWalkThroughUrl(navItem.walkthrough);
      this.setDashboardStyle(navItem.dashboardstyle);
    } else {
      this.setModuleId(0);
      this.setModuleName("");
      this.setDashboardStyle("");
      this.setTwoFARequired(false);
    }

    if (this.routeURL && this.routeURL == 'restricted-module') {
      this.getPageService().isRestrictedModule = false;
      if (this.getPageService().userPurchasesData && this.getPageService().userPurchasesData.length > 0) {
        let defaultSubscriptionInTrial = this.getPageService().userPurchasesData.find(data => { return data.subscription?.isdefault }).istrial;
        if (defaultSubscriptionInTrial) {
          let moduleID = this.getPageService().getModuleId();
          let restrictedModule = this.getPageService().restrictedModules.find(data => { return data.moduleid == moduleID });
          if (restrictedModule) {
            this.getPageService().selectedRestrictedModule = restrictedModule;
            this.getPageService().isRestrictedModule = true;
          }
        }
      }

    }


  }

  private loginNavItems: any[] = [];

  private anonymousNavItems: any[] = [{
    title: "Home",
    value: "payment",
    icon: 'fa fa-check',
    route: ''
  },

  {
    title: "Login",
    value: "settings",
    icon: 'fa fa-check',
    route: 'auth'
  },
  {
    title: "Signup",
    value: "signup",
    icon: 'fa fa-check',
    route: 'auth/signup'
  }
  ];

  getNavItems(): any[] {
    return this.isLogin() ? this.loginNavItems : this.anonymousNavItems;
  }

  isLogin() {
    return this.getPageService().isLogin();
  }

  onNavSelected(item: any) {
    console.log(item);
    if (item == "signout") {
      this.logout();
      return;
    } else if (item == "profile") {

      this.getPageService().setUserNumberUpdated(false);
    } else if (item == "emailmessage") {

      this.getPageService().setEntityScheduleScreenShow(false);
    }


    if (item == "securityquestion")
      item = "securityquestions/add";


    if (item == "emailmessage")
      item = "emailmessage/list";


    if (item == "videomessage")
      item = "videomessage/list";
    this.route(item);

    if (item == "passwordvault")
      item = "passwordvault/list";

    if (item == "myjournal")
      item = "myjournal/list";

    if (item == "virtualvault")
      item = "virtualvault/list";

    if (item == "obituary")
      item = "obituary/list";

    if (item == "lastwill")
      item = "lastwill/list";

    if (item == "documentlibrary")
      item = "documentlibrary/list";

    if (item == "documentlibrary")
      item = "documentlibrary/list";

    if (item == "companyuserimport")
      item = "companyuserimport/list";

    this.route(item);

  }


  logout() {
    this.getPageService().logout();
    this.route(this.signinRoute());
  }


  getSizeClass(): string {
    return "tele-div-" + (this.size % 3).toString();
  }

  getClass() {
    return this.getSizeClass();
  }

  resize() {
    this.size++;
  }

  reset() {
    this.size = 2;
    this.left = 0;
    this.top = 0;
    this.minimize = false;
  }

  dragged(event: any) {
    this.top = event.top;
    this.left = event.left;
  }

  top: number = 0;

  left: number = 0;


}
