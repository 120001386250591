import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PageService } from './page.service';
import { CacheService } from './cache.service';
import { EventService } from './event.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    let user = this.cache.getCacheData("sessiondata");
    return new Promise<boolean>((resolve) => {
      console.log(next.url, state.url);
      if (this.pageservice.isLogin()) {
        if (!next.url[next.url.length - 1].toString().startsWith("restricted-module")) {
          if (this.pageservice.isRestrictedModule && !this.pageservice.IsAdminUser()) {
            this.eventServ.publish2("UPDATE_RESTRICTED_MODULES", { data: this.pageservice.selectedRestrictedModule });
            this.router.navigate(["/restricted-module"])
          }
        }
        if (next.url[next.url.length - 1].toString().startsWith("manage")) {
          if (user.Data.user.modules[0].canmanage)
            resolve(true);
          else {
            resolve(false)
            this.pageservice.showInfoMsg('You cannot manage anything, either allowed space quota exceeds or no active subscriptions');
          }
        }
        else {
          resolve(true);
        }
      }
      else {
        resolve(false);
        this.pageservice.SetRedirecttUrl(state.url);
        if (this.pageservice.isActiveSubscription) {
          this.pageservice.showInfoMsg('You need to "Sign In" to access this feature');
        }
        this.router.navigate(["auth"]);
      }

      if (state.url != "/accountprocess")
        this.pageservice.checkAccountProcess();
    });
  }

  constructor(private pageservice: PageService, private router: Router, private cache: CacheService, private eventServ: EventService) {

  }
}
