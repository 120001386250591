import { Injectable } from '@angular/core';
import { HttpService, ApiResponse, ApiActionSettings } from 'src/app/core/services/http.service';

import { MessageRequest } from '../models/messagerequest';

@Injectable()
export class MessagesService {
  constructor(private http: HttpService) {
  }

  GetMessagesList(request: MessageRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity-message/list", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  GetInboxCount(): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    obj.pageFaultBypass = true;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Get("/entity-message/unread-count", undefined, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  MarkMessageAsRead(messageid: number) {
    return this.MarkMessageProcess(messageid, "Read");
  }

  MarkMessageAsUnRead(messageid: number) {
    return this.MarkMessageProcess(messageid, "UnRead");
  }

  private MarkMessageProcess(messageid: number, status: string) {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;

    let tObject: any = {};
    tObject.messageid = messageid;
    tObject.status = status;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/entity-message/set-status", tObject, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }
}
