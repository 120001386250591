<div class="row">
    <div class="col-12">
        <div class="row" *ngIf="!isFullPage()">
            <div class="col-12">
                <div class="row page-padding">
                    <div class="col-12" [ngStyle]="{'padding-left.px': getPadding(), 'padding-right.px': getPadding()}">
                        <div class="row p-0 contnt_h60">
                            <div class="col-12 d-flex">
                                <div class="my-auto p-0 mr-2" *ngIf="isSubNavFloating()">
                                    <app-icon-button iconClass="text-medium far fa-bars" (clicked)="openSubNav()"
                                        [size]="2"></app-icon-button>
                                </div>
                                <!-- <div class="pt-1 mt-2 ml-5" *ngIf="showTitle()" style="z-index:2 !important;position: fixed;">
                                    <span style="font-size: 1.4rem; font-weight: bold;" [innerHTML]="getTitle()"></span>
                                </div> -->
                                <div class="my-auto p-0 w-100" [ngClass]="{'d-none': !isScreenSupported()}">
                                    <ng-content *ngIf="showPage()" select="[position=top]"></ng-content>
                                </div>









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="row page-padding contnt_plft" [ngClass]="getNoContentClass()" *ngIf="!showPage()">
            <div class="col-12 text-center my-4" *ngIf="pageLoading() && !pageNotLoaded()">
                <img class="contnt_mtop" height="250" src="assets/images/preloader.gif">
                <!-- <h5>We are getting things ready</h5>
                <span style="font-size: 3rem;" class="fad fa-circle-notch fa-spin my-3 text-dark"></span>
                <h4 style="font-weight: bold;">Please wait while we gather data</h4> -->
            </div>

            <div class="col-12 text-center my-4" *ngIf="pageNotLoaded()">
                <h5>{{pageErrorMessage()}}</h5>
                <h1 class="mt-2 font-weight-bold">Cannot load page</h1>
            </div>
        </div>

        <div class="row page-padding" [ngClass]="getContentClass()" *ngIf="showPage()">

            <div class="col-12 d-flex" [ngStyle]="{'padding-left.px': getPadding(), 'padding-right.px': getPadding()}">
                <div id="subnav_dom" [ngClass]="getNavClass()"
                    [ngStyle]="{'min-width.px': getNavSize(), 'overflow-y' : isSubNavFloating() ? 'auto' : null  }"
                    *ngIf="navColSize > 0">
                    <div (click)="closeSubNav()" *ngIf="isSubNavFloating()">
                        <app-icon-button iconClass="far fa-chevron-circle-left" [showTitle]="true"
                            title="Close Menu"></app-icon-button>
                    </div>

                    <div [ngClass]="{'js-sticky-menu': !isSubNavFloating()}"
                        [ngStyle]="{'max-width.px': getNavSize() }">
                        <div *ngIf="getSubNavText()" class="mt-3 contnt_fsizew">{{getSubNavText()}}</div>
                        <ng-content select="[position=nav]"></ng-content>
                    </div>
                </div>

                <div class="col-12" *ngIf="!isScreenSupported()">
                    <app-generic-message title="Wider Screen Required" icon="fad fa-browser"
                        message="We need a wider screen to deliver a great experience. If you are signing into a current account, please use a laptop or download our mobile App. If you are creating a new account, please use a laptop or click on this link. <a href='https://mylifead.com/#pricing'>Signup</a></app-generic-message>">
                    </app-generic-message>
                    <p *ngIf="isDebug()" class="mt-2">
                        <span class="text-strong">Debug Message</span><br />
                        Required Width: {{this.pageservice.minSupportedScreen}}<br />
                        Current Width: {{this.pageservice.resolutionX}}<br />
                        Current Height: {{this.pageservice.resolutionY}}
                    </p>
                    <div class="row mb-2">
                        <div class="col-12 d-flex">
                            <img (click)="NavigatePlayStore()" src="/assets/images/google-play.png"
                                class="mr-2 link contnt_h40">
                            <img (click)="NavigateAppleStore()" src="assets/images/app-store.png"
                                class="link contnt_h40">
                        </div>
                    </div>
                </div>

                <div class="w-100 pl-2"
                    [ngClass]="{'pl-3': !isSubNavFloating() && navColSize > 0, 'd-none': !isScreenSupported()}">
                    <div class="row">
                        <div [hidden]="pageservice.isHideResopurceUsage()">
                            <app-resource-usage-bar
                                *ngIf="showResourceUsageBar() && pageservice.getModuleId() !=13"></app-resource-usage-bar>
                        </div>
                        <div class="col-12">
                            <ng-content select="[position=contentheader]"></ng-content>
                        </div>
                    </div>
                    <div class="row h-100">
                        <div class="col-12">
                            <ng-content select="[position=content]"></ng-content>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    </div>
</div>
<app-loader [show]="showLoader()"></app-loader>
<div class="px-3 py-1 text-white mt-1 rounded border-light contnt_pfixdbcolor" *ngIf="showLoader()">
    <span class="fas fa-circle-notch fa-spin text-white"></span>
    Loading
</div>