import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { BrowserModule } from '@angular/platform-browser';
import { MasterViewModule } from 'src/app/core/components/master-view/master-view.module';
import { PageService } from 'src/app/core/services/page.service';
import { NavigationService } from 'src/app/core/services/navigation';
import { ConfigService } from '../shared/services/config.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EventService } from 'src/app/core/services/event.service';
import { CacheService } from 'src/app/core/services/cache.service';
import { SelectModule } from 'src/app/core/components/select/select.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DialogModule } from 'src/app/core/components/dialog/dialog.module';
import { AlertMessageModule } from 'src/app/core/components/alert-message/alert-message.module';
import { AuthGuardService } from 'src/app/core/services/auth-guard.service';
import { PendingProcessingGuardService } from 'src/app/core/services/pending-processing-guard.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TruncateTextPipe } from '../shared/pipe/truncate-text.pipe';
import { ContentViewModule } from 'src/app/core/components/content-view/content-view.module';
import { AngularDraggableModule } from 'ngx-draggable-resize';
import { DeepcloneService } from '../shared/services/deepclone.service';
export function initializeApp(appConfig: ConfigService) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    MainRoutingModule,
    BrowserModule,
    MasterViewModule,
    BrowserAnimationsModule,
    ContentViewModule,
    AngularDraggableModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [ConfigService], multi: true,
  },
    PageService, NavigationService, EventService, CacheService, AuthGuardService, PendingProcessingGuardService, StorageService, DeepcloneService],
  bootstrap: [MainComponent]
})
export class MainModule { }
