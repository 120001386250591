import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/services/auth-guard.service';
import { PendingProcessingGuardService } from 'src/app/core/services/pending-processing-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  { path: "auth", loadChildren: () => import('./authentication/signin/signin.module').then(m => m.SigninModule) },
  {
    path: 'auth/forgotpassword',
    loadChildren: () => import('./authentication/forgetpassword/forgetpassword.module').then(m => m.ForgetpasswordModule)
  },
  {
    path: "auth/payment",
    loadChildren: () => import('./authentication/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: "auth/paymentinfo/:pageid",
    loadChildren: () => import('./authentication/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: "auth/setpassword",
    loadChildren: () => import('./authentication/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule)
  },
  { path: "auth/signup", loadChildren: () => import('./authentication/signup/signup.module').then(m => m.SignupModule) },
  {
    path: "auth/signup/:pageid",
    loadChildren: () => import('./authentication/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: "signupwithtoken",
    loadChildren: () => import('./authentication/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: "auth/facebooklogin",
    loadChildren: () => import('./authentication/sociallogin/facebook/facebook.module').then(m => m.FacebookModule)
  },
  {
    path: "auth/gmaillogin",
    loadChildren: () => import('./authentication/sociallogin/gmail/gmail.module').then(m => m.GmailModule)
  },
  {
    path: "auth/googlecontactsync",
    loadChildren: () => import('./authentication/sociallogin/googlecontactssync/googlecontactssync.module').then(m => m.GooglecontactssyncModule)
  },
  {
    path: "auth/verfication",
    loadChildren: () => import('./authentication/userverification/userverification.module').then(m => m.UserverificationModule)
  },
  {
    path: "auth/verfication/:pageid",
    loadChildren: () => import('./authentication/userverification/userverification.module').then(m => m.UserverificationModule)
  },
  {
    path: "auth/verifyemail",
    loadChildren: () => import('./authentication/verifyemail/verifyemail.module').then(m => m.VerifyemailModule)
  },
  {
    path: "auth/changeemail",
    loadChildren: () => import('./authentication/change-email/change-email.module').then(m => m.ChangeEmailModule)
  },



  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'contacts',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'admincontacts',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'auth/verify-contact',
    loadChildren: () => import('./authentication/verifycontact/verifycontact.module').then(m => m.VerifycontactModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'profile/editprofile',
    loadChildren: () => import('./profile/editprofile/editprofile.module').then(m => m.EditprofileModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'profile/editdetailprofile',
    loadChildren: () => import('./profile/editdetailprofile/editdetailprofile.module').then(m => m.EditdetailprofileModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'payments',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: "payments/:pageid",
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'emailmessage',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'emailmessage/manageemail/:entityId',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'emailmessage/manageemail/add',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'videomessage',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'videomessage/managevideo/:entityId',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'videomessage/managevideo/add',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'inbox',
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'trusteeaccess',
    loadChildren: () => import('./adminaccess/listing/adminaccess.module').then(m => m.AdminaccessModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'verifytrustee',
    loadChildren: () => import('./adminaccess/verifyadminaccess/verifyadminaccess.module').then(m => m.VerifyadminaccessModule)
  },
  {
    path: 'restricted-module',
    loadChildren: () => import('./restricted-module/restricted-module.module').then(m => m.RestrictedModuleModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'passwordvault',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'passwordvault/managepasswordvault/:entityId',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'passwordvault/managepasswordvault/add',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'obituary',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'obituary/manageobituray/:entityId',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'obituary/manageobituray',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'myjournal',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'myjournal/managemyjournal/:entityId',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'myjournal/managemyjournal/add',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'virtualvault',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'virtualvault/managevirtualvault/:entityId',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'virtualvault/managemyvirtualvault/add',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },

  { path: 'test', loadChildren: () => import('./test/test.module').then(m => m.TestModule) },

  {
    path: "download-play-file",
    loadChildren: () => import('./download-play-file/download-play-file.module').then(m => m.DownloadPlayFileModule)
  },

  {
    path: "securityquestions/add",
    loadChildren: () => import('./securityquestions/manage/managesecurityquestions/managesecurityquestions.module').then(m => m.ManagesecurityquestionsModule)
  },


  {
    path: "decease/mark-deceased",
    loadChildren: () => import('./markuserasdeceased/markuserasdeceased.module').then(m => m.MarkuserasdeceasedModule)
  },

  {
    path: "accountprocess",
    loadChildren: () => import('./accounttransferorpaymentparocess/accounttransferorpaymentparocess.module').then(m => m.AccounttransferorpaymentparocessModule),
    canActivate: [AuthGuardService]
  },

  {
    path: "paymentrenewal",
    loadChildren: () => import('../main/paymentrenewal/paymentrenewal.module').then(m => m.PaymentrenewalModule)
  },
  {
    path: 'lastwill',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'lastwill/managelastwill/:entityId',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'lastwill/managelastwill',
    loadChildren: () => import('./entity/entitymanagement/entitymanagement.module').then(m => m.EntitymanagementModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'documentlibrary',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'companyuserimport',
    loadChildren: () => import('./entity/entitylisting/entitylisting.module').then(m => m.EntitylistingModule),
    canActivate: [AuthGuardService],
    canDeactivate: [PendingProcessingGuardService]
  },
  { path: "rocketsign", loadChildren: () => import('./rocketsign/rocketsign.module').then(m => m.RocketsignModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
